const partial = (fn, ...cache) => (...args) => {
  const all = cache.concat(args);

  return all.length >= fn.length ? fn(...all) : partial(fn, ...all);
};

const curry =  fn => {
  return curried = (...args) => {
    if (fn.length !== args.length){
      return curried.bind(null, ...args);
    } else {
      return fn(...args);
    }
  };
};

export { curry, partial };
